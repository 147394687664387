import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/auth/authService';
import Gate from '@/gate';
import jwt from '@/http/requests/auth/jwt';
import { UserPermission } from '@/enums/UserPermission';
import { disableLoader, setPageTitle } from './helpers';

const gate = new Gate();

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: () => {
            return auth.getDefaultLoginRoute();
          },
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/views/Dashboard'),
          meta: {
            title: 'Dashboard',
            permissions: [UserPermission.VIEW_DASHBOARD.value],
            authRequired: true,
          },
        },
        {
          path: '/campaigns',
          name: 'campaigns',
          component: () => import('@/views/Campaigns'),
          meta: {
            title: 'Campaigns',
            permissions: [UserPermission.VIEW_CAMPAIGNS.value],
            authRequired: true,
          },
        },
        {
          path: '/campaigns/:id',
          name: 'campaign-products',
          component: () => import('@/views/Campaign'),
          meta: {
            title: 'Campaign',
            permissions: [UserPermission.VIEW_CAMPAIGNS.value],
            authRequired: true,
          },
        },
        {
          path: '/campaigns/:id/products/:productId/configuration',
          name: 'campaign-product-configuration',
          component: () => import('@/views/CampaignProduct'),
          meta: {
            title: 'Campaign product',
            permissions: [UserPermission.VIEW_CAMPAIGNS.value],
            authRequired: true,
          },
        },
        {
          path: '/products',
          name: 'products',
          component: () => import('@/views/Products'),
          meta: {
            title: 'Products',
            permissions: [UserPermission.VIEW_PRODUCTS.value],
            authRequired: true,
          },
        },
        {
          path: '/product-categories',
          name: 'product-categories',
          component: () => import('@/views/ProductCategories'),
          meta: {
            title: 'Product categories',
            permissions: [UserPermission.VIEW_PRODUCT_CATEGORIES.value],
            authRequired: true,
          },
        },
        {
          path: '/brands',
          name: 'brands',
          component: () => import('@/views/Brands'),
          meta: {
            title: 'Brands',
            permissions: [UserPermission.VIEW_BRANDS.value],
            authRequired: true,
          },
        },
        {
          path: '/retailers',
          name: 'retailers',
          component: () => import('@/views/Retailers'),
          meta: {
            title: 'Retailers',
            permissions: [UserPermission.VIEW_RETAILERS.value],
            authRequired: true,
          },
        },
        {
          path: '/users',
          name: 'users-list',
          component: () => import('@/views/users/user-list/UserList'),
          meta: {
            title: 'Users',
            permissions: [UserPermission.VIEW_USERS.value],
            authRequired: true,
          },
        },
        {
          path: '/users/details/:userId',
          name: 'users-details',
          component: () => import('@/views/users/UserDetails'),
          meta: {
            title: 'User details',
            permissions: [UserPermission.VIEW_USERS.value],
            authRequired: true,
          },
        },
        {
          path: '/users/:userId/edit',
          name: 'users-edit',
          component: () => import('@/views/users/user-edit/UserEdit'),
          meta: {
            title: 'User',
            permissions: [UserPermission.EDIT_USERS.value],
            authRequired: true,
          },
        },
        {
          path: '/users/create',
          name: 'users-create',
          component: () => import('@/views/users/user-create/UserCreate'),
          meta: {
            title: 'Create user',
            permissions: [UserPermission.CREATE_USERS.value],
            authRequired: true,
          },
        },
        {
          path: '/user-profile',
          name: 'profile',
          component: () => import('@/views/pages/user-settings/UserSettings'),
          meta: {
            title: 'Profile',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Profile', active: true },
            ],
            pageTitle: 'Settings',
            authRequired: true,
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login'),
          meta: {
            title: 'Login',
            fullPage: true,
          },
        },
        // {
        //   path: '/register',
        //   name: 'register',
        //   component: () => import('@/views/pages/register/Register'),
        //   meta: {
        //     title: 'Register',
        //     fullPage: true,
        //   },
        // },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import('@/views/pages/ForgotPassword'),
          meta: {
            title: 'Forgot password',
            fullPage: true,
          },
        },
        {
          path: '/reset-password',
          name: 'reset-password',
          component: () => import('@/views/pages/ResetPassword'),
          meta: {
            title: 'Reset password',
            fullPage: true,
          },
        },
        {
          path: '/verify',
          name: 'verify',
          component: () => import('@/views/pages/Verify'),
          meta: {
            title: 'Verify',
            fullPage: true,
          },
        },
        {
          path: '/verify/resend',
          name: 'verify-resend',
          component: () => import('@/views/pages/VerifyResend'),
          meta: {
            title: 'Verify',
            fullPage: true,
          },
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404'),
          meta: {
            title: '404',
          },
        },
        {
          path: '/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized'),
          meta: {
            title: '401',
          },
        },
      ],
    },
    {
      path: '*',
      redirect: '/error-404',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const isAuth = auth.isAuthenticated;
  const permissions = to.meta.permissions;
  const hasPermissions = !!permissions;

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    if (!isAuth) {
      const accessTokenExists = localStorage.getItem('accessToken');

      if (!accessTokenExists) {
        next({ name: 'login', query: { to: to.path } });
        return;
      }

      await jwt
        .refreshToken()
        .then(({ data }) => {
          const { access_token, expires_in } = data;

          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('tokenExpiry', Date.now() + expires_in * 1000);

          next();
        })
        .catch((error) => {
          console.error(error);
          next({ name: 'login', query: { to: to.path } });
        });
    }
  }

  // If user logins first time
  if (isAuth && auth.isFirstLogin() && to.name !== 'profile') {
    next({ name: 'profile' });
  }

  // It user has no permission to go to a specific route
  if (
    isAuth &&
    hasPermissions &&
    !gate.can(permissions) &&
    to.name !== 'page-not-authorized'
  ) {
    next({ name: 'page-not-authorized' });
  }

  // If full page
  if (to.meta.fullPage && auth.isExpiryValid) {
    next({ path: '/' });
  }

  next();
});

router.afterEach((to) => {
  disableLoader();
  setPageTitle(to);
});

export default router;
