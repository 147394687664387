import Vue from 'vue';
import Vuesax from 'vuesax';

// MAIN COLORS - VUESAX THEME COLORS
export const colors = {
  primary: '#0d5359',
  success: '#6d9ba6',
  danger: '#e56b26',
  warning: '#edd107',
  dark: '#1E1E1E',
  purple: '#7367f0',
};

// CONFIGS
const themeConfig = {
  disableCustomizer: true, // options[Boolean] : true, false(default)
  footerType: 'static', // options[String]  : static(default) / sticky / hidden
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  mainLayoutType: 'vertical', // options[String]  : vertical(default) / horizontal
  navbarColor: '#fff', // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'floating', // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  sidebarCollapsed: false, // options[Boolean] : true, false(default)
  theme: 'light', // options[String]  : "light"(default), "dark", "semi-dark"
};

Vue.use(Vuesax, { theme: { colors } });

export default themeConfig;
