import jwt from '@/http/requests/auth/jwt';
import axios from '@/axios';
import router from '@/router';
import { AuthAction } from '@/store/actionTypes';
import { AuthMutation } from '@/store/mutationTypes';

export default {
  [AuthAction.login]({ state }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        color: 'warning',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        time: 5000,
      });

      return false;
    }
  },
  [AuthAction.updateUsername]({ commit }, payload) {
    payload.user
      .updateProfile({
        name: payload.name,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        const newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.name = payload.name;
        commit(AuthMutation.UPDATE_USER_INFO, newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/');
        }
      })
      .catch((error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time: 5000,
        });
      });
  },
  [AuthAction.loginJWT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then((response) => {
          const { data } = response;

          // If there's user data in response
          if (data.userData) {
            // Set accessToken
            localStorage.setItem('accessToken', data.access_token);
            localStorage.setItem(
              'tokenExpiry',
              Date.now() + data.expires_in * 1000
            );

            // Set permissions
            localStorage.setItem(
              'permissions',
              JSON.stringify(data.userData.permission_names)
            );

            // Update user details
            commit(AuthMutation.UPDATE_USER_INFO, data.userData, {
              root: true,
            });

            // Set bearer token in axios
            commit(AuthMutation.SET_BEARER, data.access_token);

            resolve(response);

            router.push(router.currentRoute.query.to || '/');
          } else {
            reject({ message: 'Wrong Email or Password' });
          }
        })
        .catch(reject);
    });
  },
  [AuthAction.registerJWT]({ commit }, payload) {
    const {
      name,
      email,
      password,
      password_confirmation,
    } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== password_confirmation) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(name, email, password, password_confirmation)
        .then((response) => {
          const { data } = response;

          // Redirect User
          router.push(router.currentRoute.query.to || '/');

          // Update data in localStorage
          localStorage.setItem('accessToken', data.access_token);

          commit(AuthMutation.UPDATE_USER_INFO, data.userData, {
            root: true,
          });

          resolve(response);
        })
        .catch(reject);
    });
  },
  [AuthAction.fetchAccessToken]({ commit }) {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        const { data } = response;
        const { access_token, expires_in, userData } = data;

        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('tokenExpiry', Date.now() + expires_in * 1000);

        commit(AuthMutation.UPDATE_USER_INFO, userData, { root: true });

        resolve(response);
      });
    });
  },
  [AuthAction.recoverPassword](context, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPassword(payload.email)
        .then((response) => {
          const { data } = response;

          // If there's message in response
          if (data.message) {
            resolve(data);
          } else {
            reject({
              message: 'Wrong Email or User with this email does not exists',
            });
          }
        })
        .catch(reject);
    });
  },
  [AuthAction.resetPassword](context, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(
          payload.token,
          payload.email,
          payload.password,
          payload.password_confirmation
        )
        .then((response) => {
          const { data } = response;

          if (data.message) {
            resolve(data);
          } else {
            reject({
              message: 'Problem with reset password',
            });
          }
        })
        .catch(reject);
    });
  },
  [AuthAction.verifyEmail](context, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/auth/verify`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  [AuthAction.resendVerificationCode](context, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/auth/verify/resend`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
};
