import Vue from 'vue';
import MathUtil from '@/utils/MathUtil';

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return '';
  value = value.toString();

  const arr = value.split(replacer);
  const capitalized_array = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });

  return capitalized_array.join(' ');
});

Vue.filter('percentage', function (value) {
  const val = MathUtil.round(value);
  return `${val}%`;
});

Vue.filter('money', function (value) {
  const val = Math.round((value + Number.EPSILON) * 100) / 100;
  return `${val}`;
});

Vue.filter('numInt', function (value) {
  return Math.round(value);
});
