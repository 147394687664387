import axios from 'axios';

const { VUE_APP_API_URL, VUE_APP_API_VERSION } = process.env;

const baseURL = `${VUE_APP_API_URL}/${VUE_APP_API_VERSION}`;

export default axios.create({
  baseURL,
  // You can add your headers here
});
