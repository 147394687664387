export default {
  en: {
    Dashboard: 'Dashboard',
    Campaigns: 'Campaigns',
    Products: 'Products',
    ProductCategories: 'Product Categories',
    Brands: 'Brands',
    Retailers: 'Retailers',
    Apps: 'Apps',
    Email: 'Email',
    UI: 'UI',
    Grid: 'Grid',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Table: 'Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    Textarea: 'Textarea',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Profile: 'Profile',
    User: 'User',
    Users: 'Users',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Search: 'Search',
    Datepicker: 'Datepicker',
    I18n: 'I18n',
    Import: 'Import',
    Export: 'Export',
  },
  de: {
    Dashboard: 'Instrumententafel',
    Campaigns: 'Campaigns',
    Products: 'Products',
    ProductCategories: 'Product Categories',
    Brands: 'Brands',
    Retailers: 'Retailers',
    Apps: 'Apps',
    Email: 'Email',
    UI: 'UI',
    Grid: 'Gitter',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Farben',
    Card: 'Karte',
    Basic: 'Basic',
    Table: 'Tabelle',
    Components: 'Komponenten',
    Alert: 'Warnen',
    Avatar: 'Benutzerbild',
    Badge: 'Abzeichen',
    Breadcrumb: 'Breadcrumb',
    Button: 'Taste',
    ButtonGroup: 'Schaltflächengruppe',
    Chip: 'Chip',
    Collapse: 'Zusammenbruch',
    Dialogs: 'Dialoge',
    Divider: 'Teiler',
    DropDown: 'Dropdown-Liste',
    List: 'Liste',
    Loading: 'Wird geladen',
    Navbar: 'Navbar',
    Notifications: 'Benachrichtigungen',
    Pagination: 'Seitennummerierung',
    Popup: 'Pop-up',
    Progress: 'Fortschritt',
    Sidebar: 'Seitenleiste',
    Slider: 'Schieberegler',
    Tabs: 'Tabs',
    Tooltip: 'QuickInfo',
    Upload: 'Hochladen',
    Select: 'Wählen',
    Switch: 'Schalter',
    Checkbox: 'Ankreuzfeld',
    Radio: 'Radio',
    Input: 'Eingang',
    Textarea: 'Textbereich',
    Pages: 'Seiten',
    Authentication: 'Authentifizierung',
    Login: 'Anmeldung',
    Register: 'Registrieren',
    ForgotPassword: 'Passwort vergessen',
    ResetPassword: 'Passwort zurücksetzen',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Nicht berechtigt',
    Profile: 'Profil',
    User: 'Benutzer',
    Users: 'Users',
    View: 'Aussicht',
    Edit: 'Bearbeiten',
    UserSettings: 'Benutzereinstellungen',
    Search: 'Suche',
    Datepicker: 'Datumsauswahl',
    I18n: 'I18n',
    Import: 'Einführen',
    Export: 'Export',
  },
};
