<template>
  <label class="text-sm opacity-75">{{ text }}</label>
</template>

<script>
export default {
  name: 'vs-label',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
