export const AuthAction = Object.freeze({
  login: 'login',
  updateUsername: 'updateUsername',
  loginJWT: 'loginJWT',
  registerJWT: 'registerJWT',
  fetchAccessToken: 'fetchAccessToken',
  recoverPassword: 'recoverPassword',
  resetPassword: 'resetPassword',
  verifyEmail: 'verifyEmail',
  resendVerificationCode: 'resendVerificationCode',
});

export const ProfileAction = Object.freeze({
  changePassword: 'changePassword',
  updateProfile: 'updateProfile',
  uploadPhoto: 'uploadPhoto',
  removePhoto: 'removePhoto',
});

export const BrandAction = Object.freeze({
  add: 'addBrand',
  fetch: 'fetchBrands',
  update: 'updateBrand',
  remove: 'removeBrand',
});

export const RetailerAction = Object.freeze({
  add: 'addRetailer',
  fetch: 'fetchRetailers',
  update: 'updateRetailer',
  remove: 'removeRetailer',
});

export const CampaignAction = Object.freeze({
  add: 'addCampaign',
  fetchOne: 'fetchCampaign',
  fetch: 'fetchCampaigns',
  update: 'updateCampaign',
  remove: 'removeCampaign',
  fetchProducts: 'fetchProducts',
  addProduct: 'addProduct',
  removeProduct: 'removeProduct',
  fetchProductConfig: 'fetchProductConfig',
  updateProductConfig: 'updateProductConfig',
  addStatusSearch: 'addStatusSearch',
  updateStatusSearch: 'updateStatusSearch',
  removeStatusSearch: 'removeStatusSearch',
  fetchStatusReviews: 'fetchStatusReviews',
  updateStatusReview: 'updateStatusReview',
  fetchOfflineActivities: 'fetchOfflineActivities',
  addOfflineActivity: 'addOfflineActivity',
  updateOfflineActivity: 'updateOfflineActivity',
  removeOfflineActivity: 'removeOfflineActivity',
  addDisplayAndPaidSearchPlanning: 'addDisplayAndPaidSearchPlanning',
  updateDisplayAndPaidSearchPlanning: 'updateDisplayAndPaidSearchPlanning',
  removeDisplayAndPaidSearchPlanning: 'removeDisplayAndPaidSearchPlanning',
  fetchDisplayAndPaidSearchResult: 'fetchDisplayAndPaidSearchResult',
  updateDisplayAndPaidSearchResult: 'updateDisplayAndPaidSearchResult',
  addCrmPlanning: 'addCrmPlanning',
  updateCrmPlanning: 'updateCrmPlanning',
  removeCrmPlanning: 'removeCrmPlanning',
  fetchCrmResult: 'fetchCrmResult',
  updateCrmResult: 'updateCrmResult',
  addSocialMediaPlanning: 'addSocialMediaPlanning',
  updateSocialMediaPlanning: 'updateSocialMediaPlanning',
  removeSocialMediaPlanning: 'removeSocialMediaPlanning',
  fetchSocialMediaResult: 'fetchSocialMediaResult',
  updateSocialMediaResult: 'updateSocialMediaResult',
  addReviewPlanningItem: 'addReviewPlanningItem',
  updateReviewPlanningItem: 'updateReviewPlanningItem',
  removeReviewPlanningItem: 'removeReviewPlanningItem',
  addReviewResultItem: 'addReviewResultItem',
  updateReviewResultItem: 'updateReviewResultItem',
  removeReviewResultItem: 'removeReviewResultItem',
  fetchSummary: 'fetchSummary',
  updateSummaryOfflineActivity: 'updateSummaryOfflineActivity',
});

export const DashboardAction = Object.freeze({
  fetch: 'fetchStatistics',
});

export const ProductCategoryAction = Object.freeze({
  add: 'addProductCategory',
  fetch: 'fetchProductCategories',
  update: 'updateProductCategory',
  remove: 'removeProductCategory',
});

export const ProductAction = Object.freeze({
  add: 'addProduct',
  fetch: 'fetchProducts',
  update: 'updateProduct',
  remove: 'removeProduct',
  removePicture: 'removeProductPicture',
});

export const UserAction = Object.freeze({
  add: 'addUser',
  fetch: 'fetchUsers',
  fetchOne: 'fetchUser',
  update: 'updateUser',
  remove: 'removeUser',
  updatePermissions: 'updateUserPermissions',
});
