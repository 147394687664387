export const AuthMutation = Object.freeze({
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  SET_BEARER: 'SET_BEARER',
});

export const BrandMutation = Object.freeze({
  ADD: 'ADD_BRAND',
  SET: 'SET_BRANDS',
  UPDATE: 'UPDATE_BRAND',
  REMOVE: 'REMOVE_BRAND',
});

export const RetailerMutation = Object.freeze({
  ADD: 'ADD_RETAILER',
  SET: 'SET_RETAILERS',
  UPDATE: 'UPDATE_RETAILER',
  REMOVE: 'REMOVE_RETAILER',
});

export const CampaignMutation = Object.freeze({
  ADD: 'ADD_CAMPAIGN',
  SET: 'SET_CAMPAIGNS',
  UPDATE: 'UPDATE_CAMPAIGN',
  REMOVE: 'REMOVE_CAMPAIGN',
  SET_PRODUCTS: 'SET_PRODUCTS',
  ADD_PRODUCT: 'ADD_PRODUCT',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  SET_STATUS_REVIEW: 'SET_STATUS_REVIEW',
  SET_OFFLINE_ACTIVITIES: 'SET_OFFLINE_ACTIVITIES',
  ADD_OFFLINE_ACTIVITY: 'ADD_OFFLINE_ACTIVITY',
  UPDATE_OFFLINE_ACTIVITY: 'UPDATE_OFFLINE_ACTIVITY',
  REMOVE_OFFLINE_ACTIVITY: 'REMOVE_OFFLINE_ACTIVITY',
  SET_SUMMARY: 'SET_SUMMARY',
});

export const ProductCategoryMutation = Object.freeze({
  ADD: 'ADD_PRODUCT_CATEGORY',
  SET: 'SET_PRODUCT_CATEGORIES',
  UPDATE: 'UPDATE_PRODUCT_CATEGORY',
  REMOVE: 'REMOVE_PRODUCT_CATEGORY',
});

export const ProductMutation = Object.freeze({
  ADD: 'ADD_PRODUCT',
  SET: 'SET_PRODUCTS',
  UPDATE: 'UPDATE_PRODUCT',
  REMOVE: 'REMOVE_PRODUCT',
});

export const UserMutation = Object.freeze({
  SET: 'SET_USERS',
  REMOVE: 'REMOVE_USER',
});
