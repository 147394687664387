import axios from '@/axios';
import { DashboardAction } from '@/store/actionTypes';

export default {
  [DashboardAction.fetch](context, filters = {}) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/statistics/campaigns', filters)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
};
